/* You can add global styles to this file, and also import other style files */
@import "assets/css/theme";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body .container {
  margin-bottom: 8px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-16 {
  margin-top: 16px !important;
}

.margin-bottom-8 {
  margin-bottom: 8px;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-auto {
  margin: auto !important;
}

.margin-v-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.no-padding {
  // foi colocado 1 pq no monitoramento escondia as linhas das colunas
  padding: 0 0 0 1px;

}

.no-padding-right {
  padding-right: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-margin {
  margin: 0px !important;
}

.padding-8 {
  padding: 8px;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

// corrige centralizacao dos icones
mat-icon svg {
  vertical-align: baseline;
}

// faz os fields utilizarem 100% do espaco da COL
mat-form-field {
  display: flex !important;
  flex-direction: column !important;
}

// centraliza na vertical
.vertical-align-center {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

// Tira o espacinho no topo das listas
.mat-list-base {
  padding-top: 0px !important;
}

// quebra de liha visivel nos dialog
.mat-dialog-title {
  display: inline-table !important;
}


img { //corrige rotacao das imagens
  image-orientation: from-image !important;
}

::ng-deep .mat-form-field-appearance-outline .mat-form-field-outline {
  color: white;
}

.content {
  height: 100%;
  max-height: -moz-calc(100vh - 48px);
  max-height: -webkit-calc(100vh - 48px);
  max-height: calc(100vh - 48px);
}

::ng-deep .mat-tab-label {
  min-width: 100px !important;
}

// aumentar espaço em campos de texto para nao cortar letras 'ç'.
::ng-deep textarea.mat-input-element.cdk-textarea-autosize {
  max-height: 40px !important;
  resize: none;
}


.scroll-bar {
  overflow-x: hidden;
  scrollbar-width: thin!important;
  scrollbar-color: #0277bd white!important;

  ::-webkit-scrollbar {
    width: 5px!important;
  }

  ::-webkit-scrollbar-track {
    border-radius: 10px!important;
  }

  ::-webkit-scrollbar-thumb {
    background: #0277bd!important;
    border-radius: 10px!important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
::ng-deep .mat-menu-panel {
  min-width: 69px !important;
  margin-top: -56px;
  margin-left: 73px;
  background: transparent!important;
  box-shadow: none!important;
}


//custom scrollbar
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: map-get($primary, 800);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
